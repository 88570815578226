import React, { useState } from 'react'
import Operations1 from "../../../public/home/operations1.svg"
import Operations2 from "../../../public/home/operations2.svg"
import Waves from '../../../public/home/waves.svg';
import Image from 'next/future/image'

const HomeOperations = () => {

    const [Active, setActive] = useState(1)

    return (
        <>
            <div className="operations-container py-md-3 px-md-5  px-2">
                <div className="row g-0">
                    <div className="col pt-5">
                        <h2 className="usage-title text-center">
                            Simplifying <span className='color'>Event</span> Operations
                        </h2>
                        <div className='sub-text w-75 mx-auto text-center'>
                            KonfHub's easy-to-use mobile apps for hassle-free attendee management and capturing leads
                        </div>
                    </div>
                </div>
                <div className="d-flex desktop flex-row g-0 mt-4 px-1 ">
                    <div className="col-7 text-center">
                        <Image src={Active === 1 ? Operations1 : Operations2} className="operations-image w-100" alt="Easy event registration" width={975} height={540}/>
                    </div>
                    <div className="col-5 pe-5 d-flex align-items-center mt-5 pt-4 flex-column">
                        <div className={`${Active === 1 ? "" : "app-card-collapse"} app-card px-4 py-3`} onClick={() => setActive(1)}>
                            <div className='heading d-flex flex-column'>
                                <h3>Check-in App <i className="fa-solid float-end fa-angle-down"></i></h3>
                                <span className='sub-text-app'>No more long registration queues - we promise!</span>
                            </div>
                            <span className='description'>
                                <br />
                                Our "easy-to-use" app will help you make registrations a very smooth experience for your registration desk & for the participants.<br /><br />

                                With our app, you can quickly check-in participants, issue goodies or print lanyards. You can even customize the lanyards or do spot-registrations with the app!<br /><br />

                                What's more, there is no need to install the app (it is a progressive web app, or PWA) and can use it in Andriod or iOS.
                            </span>
                            {/* <span className='learn-more d-flex align-items-center w-25 width-small mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span> */}
                        </div>
                        <div className={`${Active === 2 ? "" : "app-card-collapse"} app-card mt-4 px-4 py-3 mb-3`} onClick={() => setActive(2)}>
                            <div className='heading d-flex flex-column'>
                                <span>Sponsors App <i className="fa-solid float-end fa-angle-down"></i></span>
                                <span className='sub-text-app'>Easily capture booth visitor details as leads</span>
                            </div>
                            <span className='description'>
                                <br />
                                Provide our app to your exhibitors or booth staff to quickly capture leads from the visitors to the booth. <br /><br />

                                Your exhibitor/sponsor booth staff can scan the QR code from the visitor lanyards and store them as leads with notes for later follow-up. Multiple booth staff? No problem - its all sinked-up as single set of leads! <br /><br />

                                What's more, there is no need to install the app (it is a progressive web app, or PWA) and can use it in Andriod or iOS.
                            </span>
                            {/* <span className='learn-more d-flex align-items-center w-25 width-small mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span> */}
                        </div>
                    </div>
                </div>
                <div className="d-flex mobile flex-column px-md-5 px-2 g-0 mt-4 ">
                    <div className='konf-card d-flex flex-column'>
                        <h3 className='heading-card'>
                            Check-in App<br />
                            <span className='sub-text-app'>No more long registration queues - we promise!</span>
                        </h3>
                        <span className='description'>
                            <br />
                            Our "easy-to-use" app will help you make registrations a very smooth experience for your registration desk & for the participants.<br /><br />

                            With our app, you can quickly check-in participants, issue goodies or print lanyards. You can even customize the lanyards or do spot-registrations with the app! <br /><br />

                            What's more, there is no need to install the app (it is a progressive web app, or PWA) and can use it in Andriod or iOS.
                        </span>
                        {/* <span className='learn-more d-flex align-items-center w-25 width-small mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span> */}
                        <Image src={ Operations1 } className="operations-image w-100" alt="" width={975} height={540}/>

                    </div>

                    <div className='konf-card mt-5'>
                        <h3 className='heading-card'>
                            Sponsors App<br />
                            <span className='sub-text-app'>Easily capture booth visitor details as leads</span>
                        </h3>
                        <span className='description'>
                            <br />
                            Provide our app to your exhibitors or booth staff to quickly capture leads from the visitors to the booth. <br /><br />

                            Your exhibitor/sponsor booth staff can scan the QR code from the visitor lanyards and store them as leads with notes for later follow-up. Multiple booth staff? No problem - its all sinked-up as single set of leads! <br /><br />

                            What's more, there is no need to install the app (it is a progressive web app, or PWA) and can use it in Andriod or iOS.
                        </span>
                        {/* <span className='learn-more d-flex align-items-center w-25 width-small mt-4'>Learn more  <i class="fas ms-2 fa-arrow-right"></i></span> */}
                        <Image src={ Operations2 } className="operations-image w-100" alt="" width={975} height={540}/>

                    </div>
                </div>
            </div>
            <div className='w-100 w-100 position-relative' style={{marginTop: "-11px"}}>
            <Image src={Waves} className="w-100 h-100 background " alt=""  width={1439} height={133}/>
            </div>
            <style>{`
        .operations-container {
            background: #451f3b;
        }
        .background{
            background: #f6f9fc;
            margin-top: -8px;
        }

        .operations-container .usage-title {
            color: #ffffff;
            font-weight: 800;
            font-size: 50px;
            font-family: "Nunito";
        }
        .color{
            color: #fb5850;
        }

        .heading-card{
            font-size: 24px;
        }

        .konf-card{
            background: rgba(243, 243, 243, 0.17);
            border: 1px solid #FFFFFF;
            box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            padding: 30px;
            margin-bottom: 20px;
        }

        .heading-card .sub-text-app{
            font-family: Nunito;
            font-size: 18px;
            font-weight: 500;
        }

        .app-card .heading{
            font-family: Nunito;
            font-size: 22px;
            font-weight: 700;
            line-height: 33px;
            letter-spacing: 0px;
            text-align: left;
            color: #ffffff;
        }

        .konf-card *{
            color: #FFFFFF;
        }

        .app-card .heading .sub-text-app{
            font-family: Nunito;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;

        }
        .app-card .description{
            font-family: Nunito;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }
        .app-card-collapse{
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid #FFFFFF;
            height: 85px;
            width: 509px;
            left: 812px;
            top: 664px;
            border-radius: 8px;
            overflow: hidden !important;
            color: #ffffff;
            box-shadow: 4px 4px 70px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
        }

        .app-card{
            background: rgba(243, 243, 243, 0.17);
            border: 1px solid #FFFFFF;
            overflow: hidden !important;
            box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            max-width: 570px !important;
            cursor: pointer;
            color: #ffffff;
        }
        .section-text{
            font-family: Nunito;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
            color: #FFFFFF;
        }
        .learn-more{
            font-family: Nunito;
            font-size: 17px;
            font-weight: 800;
            line-height: 26px;
            letter-spacing: 0px;
            cursor: pointer;
            text-align: left;
            color: #ffffff;
        }
        .participation-1{
            margin-right: -185px;
        }
        .participation-2{
            margin-right: -270px;
        }
        .operations-image{
            width: 100%;
            height: 100%;
        }
        .operations-container  .sub-text{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: #FFFFFF ;
        }
        .logos{
            margin-left: -12px;
            height: 120px;
            width: 170px;
        }
        .umbrella-sites{
            flex-direction: row;
            gap: 50px;
        }
        .site-card{
            width: 612px;
            height: 620px;
            overflow: hidden;
            text-align: left !important;
            background: #572148;
            box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
        }

        /* XS screens */
        @media only screen and (max-width: 768px) {
            .operations-container .usage-title {
                font-size: 35px;
            }
        }

        @media only screen and (max-width: 992px) {
            .desktop{
                display: none !important;
            }
        }

        @media only screen and (min-width: 992px) {
            .mobile{
                display: none !important;
            }
        }

        /* XS screens */
        @media only screen and (max-width: 480px) {
            .width-small{
                width: 50% !important;
            }
            .site-card{
                width: 320px;
            }
            .umbrella-sites{
                flex-direction: column;
            }
        }

        /* XS screens */
        @media (min-width: 480px) and (max-width: 992px) {
            .site-card{
                width: 450px;
            }
            .umbrella-sites{
                flex-direction: column;
            }
        }
        `}</style>
        </>
    )
}

export default HomeOperations
